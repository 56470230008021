import { Storage } from '@aws-amplify/storage'

export class S3Service {
  constructor(bucketName) {
    if (!bucketName) {
      throw new Error('Bucket name is required')
    }
    this.bucketName = bucketName
  }

  async uploadFile(file, path, options = {}) {
    try {

      const { level = 'public', progressCallback } = options

      await Storage.put(path, file, {
        contentType: file.type,
        bucket: this.bucketName,
        level,
        progressCallback,
        metadata: {
          originalName: file.name
        }
      })

      return this.getPresignedUrl(path, { level })
    } catch (error) {
      throw new Error(`Failed to upload file: ${error.message}`)
    }
  }

  async getPresignedUrl(key, options = {}) {
    try {
      const { expiresIn = 5, level = 'public' } = options

      return await Storage.get(key, {
        bucket: this.bucketName,
        level,
        expires: expiresIn * 60
      })
    } catch (error) {
      throw error
    }
  }
}
