import { useState } from 'react'
import Card from '@/primitives/Card'
import FlexV2 from '@/primitives/FlexV2'
import Icon from '@/primitives/Icon'
import Slot from '@/primitives/Slot'
import Text from '@/primitives/Text'
import { useTrialNotes } from './useTrialNotes'
import { NoteList } from './NoteList'
import { NoteEditor } from './NoteEditor'
import strings from '@/components/TrialModeView/Strings'
import Loader from '@/primitives/Loader'
import { cleanEditorContent } from '@/components/Shared/RichEditor/utils'


const TrialNotebook = ({ trialId }) => {
  const [note, setNote] = useState('')
  const { notes, loading, isSubmitting, addNote, deleteNote } =
    useTrialNotes(trialId)

  const handleAddNote = async () => {
    const success = await addNote(cleanEditorContent(note))

    if (success) setNote('')
  }

  return (
    <Card>
      <Loader isLoading={loading}>
        <Slot name='header'>
          <FlexV2 alignMainAxis='space-between' alignCrossAxis='center'>
            <FlexV2 alignCrossAxis='center' axisGap={200}>
              <Icon
                name='message_square'
                size={400}
                variant='primary'
                tone={600}
              />
              <Text size={400} fontWeight={600}>
                {strings.trialNotebookTitle}
              </Text>
            </FlexV2>
            <Text variant='primary' tone={600} size={300}>
              {notes.length} {strings.notesCount}
            </Text>
          </FlexV2>
        </Slot>

        <Slot name='body'>
          <FlexV2 direction='column' axisGap={400}>
            <NoteEditor
              value={note}
              onChange={setNote}
              isSubmitting={isSubmitting}
              onSubmit={handleAddNote}
              trialId={trialId}
            />
            <NoteList
              notes={notes}
              isSubmitting={isSubmitting}
              onDelete={deleteNote}
            />
          </FlexV2>
        </Slot>
      </Loader>
    </Card>
  )
}

export default TrialNotebook
